<template>
  <div class="mediumterm">
    <div class="content">
      <div class="title">证件信息</div>
    </div>
    <div class="line"></div>
    <div class="ToContent">
      <div class="kuang">
        <div>* 是否矿山</div>
        <div style="margin-left: 10px">
          <el-radio-group v-model="radio" @input="radioInput">
            <el-radio label="0" :disabled="istrue">非矿企</el-radio>
            <el-radio label="1" :disabled="istrue">矿企</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div style="margin-top: 10px">
        <el-upload class="avatar-uploader" action="#" :disabled="istrue" :show-file-list="false"
          :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <div slot="tip" class="el-upload__tip">
          <div v-if="radio=='1'">注:上传安全采矿许可证</div>
          <div v-else>注:上传工商营业执照</div>
          <div>上传文件格式仅支持JPG/JPEG/PNG格式,且小于5M文件</div>
        </div>
      </div>
    </div>

    <div class="content">
      <span class="title">公司信息</span>
      <span style="margin-left: 20px; color: red">注:采矿权人请按采矿许可证上采矿权人填写,矿山名称请按采矿许可证中矿山全称填写</span>
    </div>
    <div class="line"></div>
    <div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <div class="flex" v-if="radio== '1'">
          <div style="display: flex">
            <el-form-item label="矿山名称与采矿权人名称是否一致" label-width="230px" prop="radios">
              <span><el-radio v-model="radios" label="1" :disabled="istrue">是</el-radio></span>
              <span><el-radio v-model="radios" label="0" style="margin-left: 10px"
                  :disabled="istrue">否</el-radio></span>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="矿井类型" prop="radiotype">
              <span><el-radio v-model="radiotype" label="1" :disabled="istrue">露天矿</el-radio></span>
              <span><el-radio v-model="radiotype" label="2" :disabled="istrue"
                  style="margin-left: 10px">井工矿</el-radio></span>
            </el-form-item>
            <span></span>
          </div>
        </div>
        <div class="flex" v-if="radio == 1">
          <el-form-item label="煤矿状态" prop="mineStatus">
            <span><el-radio v-model="mineStatus" label="1" :disabled="istrue">在建煤矿</el-radio></span>
            <span><el-radio v-model="mineStatus" label="2" :disabled="istrue"
                style="margin-left: 10px">生产煤矿</el-radio></span>
          </el-form-item>
          <el-form-item label="所属集团" prop="organization">
            <el-input v-model="form.organization" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="采矿权人名称" v-if="radio == 1" prop="mineOwnerName">
            <el-input v-model="form.mineOwnerName" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="企业名称" v-if="radio != 1" prop="enterpriseName">
            <el-input v-model="form.enterpriseName" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="矿山名称(全称)" v-if="radio == 1" prop="mineAllName">
            <el-input v-model="form.mineAllName" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="统一信用代码" v-if="radio != 1" prop="socialCreditCode">
            <el-input v-model="form.socialCreditCode" :disabled="istrue"></el-input>
          </el-form-item>
        </div>

        <div class="flex" v-if="radio==1">
          <el-form-item label="中心坐标" prop="centreCoordinate">
            <el-input v-model="form.centreCoordinate" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="矿区范围" prop="mineholdings">
            <el-input v-model="form.mineholdings" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="企业类型">
            <el-select v-model="form.enterpriseTypeId" placeholder="请选择" :disabled="istrue">
              <el-option v-for="item in options" :key="item.dictId" :label="item.dictValue" :value="item.dictId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="企业性质">
            <el-select v-model="form.enterpriseNatureId" placeholder="请选择" :disabled="istrue">
              <el-option v-for="item in nature" :key="item.dictId" :label="item.dictValue" :value="item.dictId">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div class="flex">
          <el-form-item label="所属行业">
            <el-select v-model="form.industryId" placeholder="请选择" :disabled="istrue">
              <el-option v-for="item in industry" :key="item.dictId" :label="item.dictValue" :value="item.dictId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="radio == 1?'矿山所属地区':'所属地区'" prop="province">
            <el-cascader :disabled="istrue" @change="cityIdChange" v-model="form.province" :options="citys"
              placeholder="请选择所属地区" :props="{
                value: 'name',
                label: 'name',
              }"></el-cascader>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item :label="radio == 1?'矿山注册地址':'注册地址'" prop="registerAddress">
            <el-input v-model="form.registerAddress" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="contactName">
            <el-input v-model="form.contactName" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div class="flex">
          <el-form-item label="联系人办公电话" prop="contactOfficeTelephone">
            <el-input v-model="form.contactOfficeTelephone" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号码" prop="contactMobilePhone">
            <el-input v-model="form.contactMobilePhone" :disabled="istrue"></el-input>
          </el-form-item>
        </div>

        <div class="flex">
          <el-form-item label="企业电子邮箱">
            <el-input v-model="form.email" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="法定代表人姓名" prop="legalName">
            <el-input v-model="form.legalName" :disabled="istrue"></el-input>
          </el-form-item>
        </div>

        <div style="margin-left: 100px;padding-right: 130px;" v-if="radio==1">
          <el-form-item label="煤质特点">
            <el-input v-model="form.coalFeature" type="textarea" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div class="flex" v-if="radio==1">
          <el-form-item label="采矿权证及相关批复文件" label-width="200px">
            <!-- accept="image/jpg,image/jpeg,image/png" -->
            <el-upload class="upload-demo" action="#" :disabled="istrue" :http-request="addinvoiceUrl"
              ref="uploadFileref" :before-upload="beforeAvatarUploads" :before-remove="beforeRemove" multiple
              :limit="10" :on-exceed="handleExceed" :file-list="MRFileIdList">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传pdf/rar文件，且不超过200MB</div>
            </el-upload>
          </el-form-item>

          <el-form-item label="国家核定产能(万吨)" label-width="150px">
            <el-input v-model="form.approvedCapacity" type="textarea" :disabled="istrue"></el-input>
          </el-form-item>
        </div>

        <div class="content">
          <span class="title">账户信息</span>
        </div>
        <div class="line"></div>
        <div class="flex">
          <el-form-item label="开户名称">
            <el-input v-model="form.accountName" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="开户行所在省市">
            <el-input v-model="form.bankCity" :disabled="istrue"></el-input>
          </el-form-item>
        </div>

        <div class="flex">
          <el-form-item label="开户网点" prop="openingBranch">
            <el-input v-model="form.openingBranch" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="开户行银联号" prop="unionpayNo">
            <el-input v-model="form.unionpayNo" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div class="flex" v-if="radio != 1">
          <el-form-item label="开户行名称" prop="depositBankName">
            <el-input v-model="form.depositBankName" :disabled="istrue"></el-input>
          </el-form-item>
          <el-form-item label="企业银行账号" prop="bankAccount">
            <el-input v-model="form.bankAccount" :disabled="istrue"></el-input>
          </el-form-item>
        </div>
        <div style="text-align: center">
          <el-form-item>
            <el-button type="primary" @click="onSubmit('form')" v-if="!istrue">提交认证</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { getTreeV2, applyMiningAuth, ossUploadFile2 } from "@/api/Medium.js";
  import { getSysDictDataByByDictType, detailMiningAuth } from "@/api/hy";
  import { ossUploadFile } from "@/api/public";
  import Vue from "vue";
  import * as types from "@/store/action-types";
  const { baseUrl } = require("@/config");
  export default {
    data() {
      return {
        MRFileIdList: [],
        istrue: true,
        baseUrl: baseUrl,
        citys: [],
        industry: [],
        options: [],
        nature: [],
        mineStatus: '1',
        form: {
          safeMiningLicense: "",
          value: "",
          name: "",
          cityId: "",
          mineStatus: '1',
          openingBranch: '',
          unionpayNo: '',
          fileIdList: []
        },
        rules: {
          radio: [
            {
              required: true,
              message: "请选择矿山名称与采矿权人名称是否一致",
              trigger: "change",
            },
          ],
          mineStatus: [
            { required: true, message: "请选择煤矿状态", trigger: "change" },
          ],
          radio: [
            { required: true, message: "请选择矿井类型", trigger: "change" },
          ],
          mineOwnerName: [
            { required: true, message: "请输入采矿权人名称", trigger: "blur" },
          ],
          enterpriseName: [
            { required: true, message: "请输入企业名称", trigger: "blur" },
          ],
          mineAllName: [
            { required: true, message: "请输入矿山名称", trigger: "blur" },
          ],
          socialCreditCode: [
            { required: true, message: "请输入统一信用代码", trigger: "blur" },
          ],
          enterpriseTypeId: [
            { required: true, message: "请选择企业类型", trigger: "change" },
          ],
          enterpriseNatureId: [
            { required: true, message: "请选择企业性质", trigger: "change" },
          ],
          industryId: [
            { required: true, message: "请选择所属行业", trigger: "change" },
          ],
          province: [
            { required: true, message: "请选择所属地区", trigger: "change" },
          ],
          registerAddress: [
            { required: true, message: "请输入注册地址", trigger: "blur" },
          ],
          contactName: [
            { required: true, message: "请输入联系人姓名", trigger: "blur" },
          ],
          contactOfficeTelephone: [
            { required: true, message: "请输入联系人办公手机", trigger: "blur" },
          ],
          email: [
            { required: true, message: "请输入企业电子邮箱", trigger: "blur" },
          ],
          legalName: [
            { required: true, message: "请输入法定代表人姓名", trigger: "blur" },
          ],
          accountName: [
            { required: true, message: "请输入开户名称", trigger: "blur" },
          ],
          bankCity: [
            { required: true, message: "请输入开户行所在省市", trigger: "blur" },
          ],
          contactMobilePhone: [
            { required: true, message: "请输入联系人手机号", trigger: "blur" },
          ],
          // openingBranch: [
          //   { required: true, message: "请输入开户网点", trigger: "blur" },
          // ],
          // unionpayNo: [
          //   { required: true, message: "请输入开户行银联号", trigger: "blur" },
          // ],
          // depositBankName: [
          //   { required: true, message: "请输入开户行名称", trigger: "blur" },
          // ],
          // bankAccount: [
          //   { required: true, message: "请输入企业银行账号", trigger: "blur" },
          // ],
        },
        radio: "1",
        radios: "1",
        radiotype: "1",
        dialogImageUrl: "",
        imageUrl: "",
        list: []
      };
    },
    mounted() {
      this.getTree();
      this.getde();
      let a = this.$route.query.type;
      console.log(a);
      if (a == "待审核") {
        this.istrue = true;
      } else {
        this.istrue = false;
      }
    },
    methods: {
      radioInput(e) {
        if (e) {
          this.$refs["form"].resetFields();
        }
      },
      beforeAvatarUploads(file) {
        // return new Promise((resolve, reject) => {
        //   console.log(file)
        //   const isJPG = file.type === 'application/pdf';
        //   const isRAR = file.type === 'application/x-rar';
        //   const isLt2M = file.size / 1024 / 1024 < 200;
        //   if (!isJPG||!isRAR) {
        //     this.$message.error('上传文件只能是 pdf/rar 格式!');
        //     return reject(false);
        //   }else if (!isLt2M) {
        //     this.$message.error('上传文件大小不能超过200MB!');
        //     return reject(false);
        //   }else{
        //     return resolve(true);
        //   }
        //   console.log(isJPG && isLt2M)
        // })

      },
      addinvoiceUrl(file) {
        // let otherFiles = file.file;
        // const fileSuffix = otherFiles.name.substring(otherFiles.name.lastIndexOf('.') + 1)
        // const whiteList = ['pdf']
        // console.log(whiteList.indexOf(fileSuffix))
        // if (whiteList.indexOf(fileSuffix) === -1) {
        //   this.$message({
        //     message: '上传文件只能是 pdf',
        //     type: 'warning'
        //   })
        //   return false
        // }
        // const isJPG = otherFiles.type === "image/jpg";
        // const isPng = otherFiles.type === "image/png";
        // const isJpeg = otherFiles.type === "image/jpeg";
        // //1MB=1024*1024(1MB=1024KB 1KB=1024MB)
        // const is10M = otherFiles.size / 1024 / 1024 < 10;
        // //限制文件上传类型
        // console.log(!isJPG && !isPng && !isJpeg, !isJPG, !isPng, !isJpeg)
        // if (!isJPG && !isPng && !isJpeg) {
        //   this.$message.error("上传图片只能是 png,jpg,jpeg 格式!");
        //   return false
        // }
        // //限制文件上传大小
        // if (!is10M) {
        //   this.$message.error("上传图片大小不能超过 10MB!");
        //   return false
        // }
        let _this = this
        let otherFiles = file.file;
        var formData = new FormData();
        // 文件对象
        formData.append("file", otherFiles);
        ossUploadFile2(formData).then((res) => {
          if (res.code == 0) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            _this.list.push(res.data.fileId)
            _this.form.fileIdList = _this.list
            console.log(_this.list)
          }
        });
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
      },
      beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${file.name}？`);
      },
      getde() {
        // detailMiningAuth
        let id = Vue.ls.get(types.enterpriseId);
        let config = {
          enterpriseId: id,
        };
        detailMiningAuth(config).then((res) => {
          if (res.code == 0) {
            // mineStatus
            this.mineStatus = res.data.mineStatus.toString()
            // this.radio = res.data.ifMine.toString();
            if (res.data.ifMine) {
              this.imageUrl =
                baseUrl + "third/third/ossGetFile/" + res.data.safeMiningLicense;
              this.dialogImageUrl = res.data.safeMiningLicense;
            } else {
              this.imageUrl =
                baseUrl + "third/third/ossGetFile/" + res.data.businessLicense;
              this.dialogImageUrl = res.data.businessLicense;
            }
            if (res.data.mrfileList) {
              let helist = []

              res.data.mrfileList.forEach(item => {
                console.log(item)
                helist.push({ name: item.originalName, url: baseUrl + "third/third/ossGetFile/" + item.storageName })
              });
              this.MRFileIdList = helist

            }



            this.radios = String(res.data.ifSameName);
            this.radiotype = String(res.data.mineType);
            this.form = res.data;
            let list = [];
            list.push(res.data.province);
            list.push(res.data.city);
            list.push(res.data.area);
            this.form.province = list;
          }
        });
      },
      handleAvatarSuccess(res, file) {
        console.log(file);
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === "image/jpeg" || "image/png" || "image/JPEG";
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isJPG) {
          this.$message.error("上传头像图片只能是 JPG/png 格式!");
          return false;
        }
        if (!isLt2M) {
          this.$message.error("上传头像图片大小不能超过 5MB!");
          return false;
        }
        var formData = new FormData();
        // 文件对象
        formData.append("file", file);
        console.log(file);
        ossUploadFile(formData).then((res) => {
          if (res.code == 0) {
            this.dialogImageUrl = res.data;
            this.imageUrl = baseUrl + "third/third/ossGetFile/" + res.data;
            this.$message({
              message: res.message,
              type: "success",
            });
          } else {
            this.$message.error(res.message);
          }
        });
        // return isJPG && isLt2M;
      },
      // 提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.radio == 1) {
              this.form.safeMiningLicense = this.dialogImageUrl;
            } else {
              this.form.businessLicense = this.dialogImageUrl;
            }
            this.form.mineStatus = this.mineStatus
            this.form.ifMine = this.radio;
            this.form.ifSameName = this.radios;
            this.form.mineType = this.radiotype;
            this.form.area = this.form.province[2];
            this.form.city = this.form.province[1];
            this.form.province = this.form.province[0];

            let config = {
              ...this.form,
            };
            applyMiningAuth(config).then((res) => {
              if (res.code == 0) {
                this.$message({
                  message: res.data,
                  type: "success",
                });
                setTimeout(() => {
                  this.$router.push({
                    path: "/userIndex",
                  });
                }, 1000);
              } else {
                this.$message.error(res.message);
              }
            });
          }

        })

      },
      cityIdChange(e) {
        console.log(e);
        // this.form.cityId = e[2];
      },
      getTree() {
        getTreeV2().then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.citys = this.deleteChildren(res.data);
          }
        });
        // 企业类型
        let config = {
          dictType: "mine_enterprise_type",
          pageNum: 1,
          pageSize: 100,
        };
        getSysDictDataByByDictType(config).then((res) => {
          if (res.code == 0) {
            this.options = res.data.list;
          }
        });
        // 企业性质
        let configs = {
          dictType: "mine_enterprise_nature",
          pageNum: 1,
          pageSize: 100,
        };
        getSysDictDataByByDictType(configs).then((res) => {
          if (res.code == 0) {
            this.nature = res.data.list;
          }
        });
        // 所属行业
        let confi = {
          dictType: "mine_industry",
          pageNum: 1,
          pageSize: 100,
        };
        getSysDictDataByByDictType(confi).then((res) => {
          if (res.code == 0) {
            this.industry = res.data.list;
          }
        });
      },
      deleteChildren(arr) {
        let childs = arr;
        for (let i = childs.length; i--; i > 0) {
          if (childs[i].children) {
            if (childs[i].children.length) {
              this.deleteChildren(childs[i].children);
            } else {
              delete childs[i].children;
            }
          }
        }
        return arr;
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
    justify-content: space-around;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    border: 1px dashed #ccc;
    border-radius: 6px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .mediumterm {
    width: 1195px;
    margin: 15px auto;
    background: white;

    &::before,
    &::after {
      display: table;
      content: "";
    }
  }

  .content {
    padding: 20px;
  }

  .title {
    font-size: 16px;
  }

  .line {
    width: 100%;
    height: 1px;
    background: #ccc;
    margin-bottom: 20px;
  }

  .ToContent {
    padding: 10px 30px;
  }

  .kuang {
    font-size: 15px;
    color: red;
    display: flex;
  }
</style>